import { createStore } from 'vuex'

export default createStore({
  state: {
    visible_p1: true,
    visible_p2: false,
    visible_p3: false,
    visible_p4: false,
    visible_p5: false,
    flechedb: true,
    flechedh: false,
  },
  getters: {
  },
  mutations: {

    // keep in the store the elements
    setPage1(state, visible_p1) {
      state.visible_p1 = visible_p1;
    },
    setPage2(state, visible_p2) {
      state.visible_p2 = visible_p2;
    },
    setPage3(state, visible_p3) {
      state.visible_p3 = visible_p3;
    },
    setPage4(state, visible_p4) {
      state.visible_p4 = visible_p4;
    },
    setPage5(state, visible_p5) {
      state.visible_p5 = visible_p5;
    },
    setFlecheH(state, flechedh) {
      state.flechedh = flechedh;
    },
    setFlecheB(state, flechedb) {
      state.flechedb = flechedb;
    },

  },
  actions: {
  },
  modules: {
  }
})
