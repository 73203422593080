<!-- This is the home page with presentation of the application. -->

<template>
  <BgIntroComponent/>
  <main>
  </main>
</template>

<script>

  import BgIntroComponent from '@/components/BgIntroComponent.vue'


  export default {
    name: 'Intro-vue',
    components: {
      BgIntroComponent,

    },
    data() {
      return {
        
      }
    },
    computed: {
      
    },
    methods: {
      
    },

    

  }
</script>

<style lang="scss">


</style>