<!-- This is the first block for the desktop media . -->

<template>

        <div class="mob_padding0 mx-auto row text-center align-items-center justify-content-center heightTitle">

          <h2 class="col-12 pinktitle"> 
          Contact
          </h2>

        </div>
        <div class="mx-auto row align-items-center heightElt">
          <div class="col-6 text-center padding0">
            <div class="mx-auto row align-items-center justify-content-between"> 
              <div class="mx-auto row text-center justify-content-center">
                <p class="my-auto rectfluo rectlarge scrolly"> 

                <!-- Ici est inséré le texte -->
                  <TextPage5/>

                </p>
              </div>
                
            </div>
          </div>
          <div class="col-6 align-self-center" id="photop2">
            <img id="img2" alt="Image de profil" src="../assets/taille2.png">
          </div>
        </div>
        
</template>

<script>

import TextPage5 from '@/components/TextPage5.vue'

  export default {

    name: "Page5-c",
    components: {
      TextPage5,
    },
    data() {return {}},
    computed: {},
    methods: {},
    mounted() {}
  }

</script>


<style lang="scss">
  
  #photop2 {
   text-align: center;
    img {
      width:308px;
      height:537px;
      transform: rotateY(0.5turn);
    }
  }

  .padding0 {
   padding:0;
  }
  
  
</style>