<!-- This is the header component for all pages except Signin and Signup vue. -->

<template>
  <div id="background">
      <canvas id="canv2"></canvas>
      <div id="bg-transparent2"></div>
  </div>
</template>

<script>

  
  export default {

    name: "BG-intro-c",
    data() {return {}},
    computed: {},
    methods: {},
    mounted() {

        const canvas = document.getElementById('canv2');
        const ctx = canvas.getContext('2d');
        

        // set the width and height of the canvas
        const w = canvas.width = document.body.offsetWidth;
        const h = canvas.height = window.innerHeight;
        const cols = Math.floor(w / 20) + 1;
        const ypos = Array(cols).fill(0);

        // draw a black rectangle of width and height same as that of the canvas
        ctx.fillStyle = '#000';
        ctx.fillRect(0, 0, w , h);
        let compt = 0;

        const text1 = "GREG@IRE AIMIER";
        var test1 = text1.split('');
        var zz = (cols - test1.length + 1)/2;
        var testblanc = "";
        for (let i = 1; i <= zz; i++) {
          testblanc = " " + testblanc;
        }
        var test = testblanc + text1 + testblanc;
        var maxCompt1 = 60;
        var maxCompt2 = maxCompt1 + 200;

        var tab = Array(cols).fill(0);
        // console.log(tab);


        function matrix () {
          compt += 1;
          // Draw a semitransparent black rectangle on top of previous drawing
          ctx.fillStyle = '#0001';
          ctx.fillRect(0, 0, w, h);
          
          // Set color to green and font to 15pt monospace in the drawing context
          ctx.fillStyle = '#0f0';
          ctx.font = '15pt monospace';
          


          // for each column put a random character at the end
          ypos.forEach((y, ind) => {
            if (compt < maxCompt1) {
              // generate a random character
              const text = String.fromCharCode(Math.random() * 128);
              // x coordinate of the column, y coordinate is already given
              const x = ind * 20;
              // render the character at (x, y)
              ctx.fillText(text, x, y);
              // randomly reset the end of the column if it's at least 100px high
              if (y > 100 + Math.random() * 10000) ypos[ind] = 0;
              // otherwise just move the y coordinate for the column 20px down,
              else ypos[ind] = y + 20;
              

            } else {

              if (tab[ind]== 0){
                // generate a random character
                const text = String.fromCharCode(Math.random() * 128);
                // x coordinate of the column, y coordinate is already given
                const x = ind * 20;
                // render the character at (x, y)
                ctx.fillText(text, x, y);
                // randomly reset the end of the column if it's at least 100px high
                if (y > 100 + Math.random() * 10000){
                  ypos[ind] = 0;
                  tab[ind] = 1
                // otherwise just move the y coordinate for the column 20px down,
                } else ypos[ind] = y + 20;

              } else {

                const text = test[ind];

                // x coordinate of the column, y coordinate is already given
                const x = ind * 20;
                // render the character at (x, y)
                ctx.fillText(text, x, y);

                
                if (y >= Math.round(h/40)*20){
                  ypos[ind] = Math.round(h/40)*20;
                } else {
                  // otherwise just move the y coordinate for the column 20px down,
                  ypos[ind] = y + 20;

                }
              }

            }
          })
          if (compt == maxCompt2) {
            clearInterval(id);
          }
        }

        const id = setInterval(matrix, 40);

        document.body.onclick = function() {
          // this.$router.push("/accueil");
          window.open('#/home',"_self")
        }


      },

  };

</script>


<style lang="scss">

  
  #background{
    margin:0 ;
  }

  #canv2{ 
    display: flex;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    margin: 0;
    background: #131313;
    color: #fff;
    font-size: 96px;
    font-family: 'Fira Mono', monospace;
    letter-spacing: -7px;
    z-index: 0;
    background-position: center;
    background-repeat: repeat;
    background-size: cover;
    padding:0;

  }

  #bg-transparent2{
    width: 100vw;
    height: 450px;
    z-index : 1;
    display: flex;
    position:absolute;
    left:0px;
    top:0px; 
    background: linear-gradient(to top, transparent, black);
    position:fixed;
  }
</style>