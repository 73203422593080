
<template>
  Salut, moi c'est Grégoire et je suis développeur WEB. <br>
  Bienvenue sur mon site !  <br> <br> 

      TEL : <a href="tel:0656670596" target="_blank">06 56 67 05 96</a><br> 
      email : <a href="mailto:gregoire@aimier.fr" target="_blank">gregoire@aimier.fr</a> <br>
    Adresse : <a href="https://goo.gl/maps/pAHxMEeiKfbKMe1z6" target="_blank">LACANAU </a> 

    <div v-if="maxWidth === false">
    <br>
      <a href="https://www.linkedin.com/in/gr%C3%A9goire-aimier-5840101aa" target="_blank">LinkedIn</a> 
      / <a href="https://www.instagram.com/gregoireaimier" target="_blank">Instagram</a>
      / <a href="https://www.facebook.com/public/Grégoire-Aimier" target="_blank">Facebook</a>
      / <a href="https://readytoweb.fr" target="_blank">readytoweb.fr</a>
      <!-- / <a href="https://lacanau.dev" target="_blank">lacanau.dev</a> -->
    </div>


</template>

<script>

  export default {

    name: "TexteP1",
    data() {return {}},
    computed: {
      // calculate the maxWidth of the screen
      maxWidth() {
        if (window.matchMedia("(max-width: 768px)").matches) {
          return true
        } else { return false } 
      },
    },
    methods: {},
    mounted() {}
  }

</script>


<style lang="scss">

  
</style>