
<template>
  <div v-if="maxWidth === false">
    <h3>N'hésitez pas à me contacter !</h3>
    <p>
      TEL : <a href="tel:0656670596" target="_blank">06 56 67 05 96</a><br> 
      email : <a href="mailto:gregoire@aimier.fr" target="_blank">gregoire@aimier.fr</a> <br>
      Adresse : <a href="https://goo.gl/maps/pAHxMEeiKfbKMe1z6" target="_blank">LACANAU </a> 
    </p>
  </div>
  <!-- If max-width of the screen is < 768 px this part is active -->
  <div class="row">
    <a class="col iconmargin" href="https://www.readytoweb.fr" target="_blank">
      <img id="logoRTW" alt="Logo de ready to web" src="../assets/Logo_RTW2.png">
    </a>
    <a class="col iconmargin" href="https://www.linkedin.com/in/gr%C3%A9goire-aimier-5840101aa" target="_blank">
      <img class="imgsocial" alt="Logo de linkedin" src="../assets/in_linked_linkedin_media_social_icon_124259.png">
    </a>
    <a class="col iconmargin" href="https://twitter.com/AimierGregoire" target="_blank">
      <img class="imgsocial" alt="Logo de twitter" src="../assets/IconeTwitter.png">
    </a>
    <a class="col iconmargin" href="https://www.instagram.com/gregoireaimier" target="_blank">
      <img class="imgsocial" alt="Logo de instagram" src="../assets/IconeInsta.png">
    </a>
    <a class="col iconmargin" href="https://www.facebook.com/public/Grégoire-Aimier" target="_blank">
     <img class="imgsocial" alt="Logo de facebook" src="../assets/IconeFacebook.png">
    </a> 
    
  </div>

</template>

<script>

  export default {

    name: "TexteP5",
    data() {return {}},
    computed: {
      
      // Calculate the max-width of the screen
      maxWidth() {
        if (window.matchMedia("(max-width: 768px)").matches) {
          return true
        }
        else { return false }
      }
    },
    methods: {},
    mounted() {}
  }

</script>


<style lang="scss">

 .imgsocial {
  width:40px;
  height:40px;
  margin:10px;
 }
 #logoRTW {
  width:40px;
  height:auto;
  margin:18px 10px;
 }


</style>