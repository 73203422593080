
<template>
  
  <div class="timeline" v-if="maxWidth === false">
    <div class="row test align-items-center" v-for="(item, index) in items" v-bind:key="item.name">
      <div class="col-5"></div>
      <div class="col-2">
        <h4 v-if="(index%2 == 0)" class="timeline__item timeline__item2 timeline__item--year">{{item.date}}</h4>
      </div>
      <div class="col-5">
        <div v-if="(index%2 == 0)" class="timeline__item timeline__item2 trigger">
          <h5 class="timeline__title">{{item.society}}</h5>
          <p>{{item.title}}</p>
          <div class="hidden" v-if="(item.description.length > 1)">
            <p class="list text-left" v-for="elt in item.description" v-bind:key="elt">- {{elt}}</p>
          </div>
          <p  class="hidden list text-left" v-if="(item.description.length == 1)">{{item.description[0]}}</p>
        </div>
      </div>
      
      <div class="col-5">
        <div v-if="(index%2 != 0)" class="timeline__item trigger">
          <h5 class="timeline__title">{{item.society}}</h5>
          <p>{{item.title}}</p>
          <div class="hidden" v-if="(item.description.length > 1)">
            <p class="list text-left" v-for="elt in item.description" v-bind:key="elt">- {{elt}}</p>
          </div>
          <p  class="hidden list text-left" v-if="(item.description.length == 1)">{{item.description[0]}}</p>
        </div>
      </div>
      <div class="col-2">
        <h4 v-if="(index%2 != 0)" class="timeline__item timeline__item--year">{{item.date}}</h4>
      </div>
      <div class="col-5"></div>
      
    </div>
    
  </div>

  <div class="" v-if="maxWidth === true">
     <div class="row test align-items-center " v-for="(item, index) in items" v-bind:key="item.name">

        <div class="">
          <h4 v-if="(index%2 == 0)" class="timeline__item--mobile1 timeline__item--year">{{item.date}}</h4>
        </div>
        <div class="">
          <div v-if="(index%2 == 0)" class="marginbottomxp">
            <h5 class="timeline__title pinkxptitle">{{item.society}}</h5>
            <p class="xptitle">{{item.title}}</p>
            <div class="" v-if="(item.description.length > 1)">
              <p class="list2 text-left" v-for="elt in item.description" v-bind:key="elt">- {{elt}}</p>
            </div>
            <p  class=" list2 text-left" v-if="(item.description.length == 1)">{{item.description[0]}}</p>
          </div>

      </div>

        <div class="">
          <h4 v-if="(index%2 != 0)" class="timeline__item--mobile2 timeline__item--year">{{item.date}}</h4>
        </div>
        <div class="">
          <div v-if="(index%2 != 0)" class="marginbottomxp">
            <h5 class="timeline__title pinkxptitle">{{item.society}}</h5>
            <p class="xptitle">{{item.title}}</p>
            <div class="" v-if="(item.description.length > 1)">
              <p class="list2 text-left" v-for="elt in item.description" v-bind:key="elt">- {{elt}}</p>
            </div>
            <p  class="list2 text-left" v-if="(item.description.length == 1)">{{item.description[0]}}</p>
          </div>
        </div>

     
      
    </div>
  </div>

</template>

<script>

  export default {

    name: "TexteP4",
    data() {
      return {
      //   <ul v-if="(item.description.length > 1)">
      //   <li v-for="elt in item.description" v-bind:key="elt">{{elt}}</li>
      // </ul>
      // <label for='work5'>Lorem ipsum dolor sit amet</label>
        items: [
          {date:2022, society: "READY TO WEB", title:"Fondateur & Développeur WEB", description:["Création de sites internet", "Diplôme de Développeur WEB avec OPENCLASSROOMS"] },
          {date:2020, society: "STELIA COMPOSITES", title:"Ingénieur Master Planner", description:["Création d'outils de planification & d'indicateurs (Power BI / VBA)", "Formations aux outils"] },
          {date:2017, society: "STELIA COMPOSITES", title:"Responsable PC&L* A350", description:["Management de 4 personnes", "Création d'un ERP 'VBA'",  "Gestion & suivi de production"] },
          {date:2014, society: "STELIA COMPOSITES", title:"PC&L* A350", description:["Création d'indicateurs (VBA)", "Planification en production de 700 références",  "Interface client (international)" ] },
          {date:2011, society: "STELIA COMPOSITES", title:"PMO** / Chef de projets", description:[" Homologation de réservoirs gaz", " Suivi opérationnel des projets(planning / livrables / Indicateurs)" ] },
          {date:2009, society: "STELIA COMPOSITES", title:"PC&L* ATR", description:[" Suivi de production", "Ordonnancement & Planification","Interface client","création d'outils d'amélioration du SI & de planification" ] },
          {date:2007, society: "ALSTOM (consultant MI-GSO)", title:"Ingénieur Gestion de projet", description:["Gestion de configuration", "Formation 'internationale'",  "Gestion & suivi de production"] },
          {date:2006, society: "THALES (consultant CS-SI)", title:"Ingénieur Gestion de projet", description:["Gestion de configuration", "PMO**"] },
          // {date:2006, society: "EIGSI", title:"Ecole d'ingénieur en génie des systèmes industriels", description:["Formation Ingénieur généraliste"] },
        ],
        show:[],
        active : false,

      }
    },
    computed: {
      // calculate the maxWidth of the screen
      maxWidth() {
        if (window.matchMedia("(max-width: 768px)").matches) {
          return true
        } else { return false } 
      },
    },
    methods: {
      mouseOver: function(){
            this.active = !this.active;   
        }
    },
    mounted() {}
  }

</script>


<style lang="scss">
.hidden { 
  display: none;
  p {
    margin :0;
  } 
  }
.trigger:hover .hidden { display: block; }
$color: #00c853;
$lineColor: #f21ee6eb;
// $color: skyblue;
// $lineColor: hotpink;
$fontColor: #bbb;
$bgColor1: #222;
$bgColor2: #444;
$yearBgColor: #000;

$breakpoint: 768px;

* {
  box-sizing: border-box;
}

body {
  background-image: linear-gradient(to bottom, $bgColor1, $bgColor2);
  color: $fontColor;
  // padding: 1rem;
  font-family: 'Playfair Display', serif;
}



.timeline {
  // @media screen and (max-width: $breakpoint) {
  // position: relative;
  // // padding: 1rem;
  // // margin: 0 auto;
  // max-width: 200px;
   
  // }
  
  p {
    margin :0;
  }
  position: relative;
  // padding: 1rem;
  // margin: 0 auto;
  max-width: 1300px;
  
  &:before {
    content: '';
    position: absolute;
    height: 100%;
    border: 1px solid $lineColor;
    // right: 40px;
    top: 0;
    left: calc(50% - 1px);
    right: auto;
  }
  
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  
  
}



.timeline__item {
  
  // padding: 1rem;
  padding: 0.5rem;
  border: 2px solid $lineColor;
  border-image: linear-gradient(to right, $color 0%, $lineColor 100%);
  border-image-slice: 1;
  position: relative;
  // margin: 0.5rem;
  clear: both;
  
  &:after,
  &:before {
    content: '';
    position: absolute;
  }
  
  &:before {
    right: -10px;
    top: calc(50% - 10px);
    border-style: solid;
    border-color: $lineColor $lineColor transparent transparent;
    border-width: 10px;
    transform: rotate(45deg);
  }
  
  @media screen and (min-width: $breakpoint) {
    // width: 44%;
    // margin: 1rem;
    
    &:nth-of-type(2n) {
      // float: right;
      // margin: 1rem;
      border-image: linear-gradient(to right, $lineColor 0%, $color 100%);
      border-image-slice: 1;

      &:before {
        right: auto;
        left: -10px;
      border-color:transparent transparent $lineColor $lineColor;
      }
    }
  }
}

.timeline__item2 {

    
      // float: right;
      // margin: 1rem;
      border-image: linear-gradient(to right, $lineColor 0%, $color 100%);
      border-image-slice: 1;

       &:before {
          right: auto;
          left: -10px;
      border-color:transparent transparent $lineColor $lineColor;

     }

  
}


.timeline__item--year {
  text-align: center;
  // max-width: 150px;
  // margin: 0 48px 0 auto;
  // font-size: 1.8rem;
  background-color: $yearBgColor;
  line-height: 1;
  border-image: none;
  padding: .5rem;
  
  &:before {
    display: none;
  }
  
  @media screen and (min-width: $breakpoint) {
    text-align: center;
      // margin: 0 auto;
    
    &:nth-of-type(2n) {
      float: none;
      margin: 0 auto;
      border-image: none;
  
      &:before {
        display: none;
      }
    }
  }
  @media screen and (max-width: $breakpoint) {
    font-size: 14px;
  }
}

.timeline__title {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  // font-size: 1.5em;
   @media screen and (max-width: $breakpoint) {
    font-size: 16px;
   }
}



.timeline__item--mobile2 {
  border: 2px solid $lineColor;
  border-image: linear-gradient(to right, $lineColor 0%, $color 100%);
  border-image-slice: 1;     
}
.timeline__item--mobile1 {
  border: 2px solid $lineColor;
  border-image: linear-gradient(to left, $lineColor 0%, $color 100%);
  border-image-slice: 1;     
}

.xptitle{
  margin-bottom: 2px;
  font-weight:bold;
  font-size: 15px;

  
}

.list2{
  font-size: 12px;
  margin-bottom: 0px;
  
}
.marginbottomxp{
  margin-bottom: 10px;
  
}

  
</style>