
<template>

  Je m'appelle donc Grégoire AIMIER, j'ai 40 ans, en couple avec 2 enfants. <br><br>
  Après un bac scientifique et 5 années d'école d'ingénieur, je me suis orienté vers l'industrie et la gestion de projets ferroviaires et aéronautiques. 
  Durant 14 années, j'ai appris à gérer des projets, des budgets, des plannings, mais aussi à comprendre et à répondre aux besoins des clients. 
  Afin d' optimiser mon travail et celui de mon équipe, j'ai conçu des outils informatiques simples (Excel) et souvent complexes (VBA). 
  C'est à partir de là qu'est née mon attirance pour le développement informatique. <br><br>
  L'arrivée de la COVID 19 et la démocratisation du télétravail m'ont fait réaliser qu'il fallait donner un tournant à ma vie professionnelle 
  en me reconvertissant dans du développement web "à la maison". J'ai donc quitté mon emploi et intégré début 2021 une formation de 6 mois en distanciel chez "OpenClassrooms". 
  Après quelques formations complémentaires (Python / Wordpress / Outils Adobe Xd &amp; Illustrator), j'ai créé mon entreprise de développement informatique : "Ready To Web".

</template>

<script>

  export default {

    name: "TexteP2",
    data() {return {}},
    computed: {},
    methods: {},
    mounted() {}
  }

</script>


<style lang="scss">

  
</style>