<!-- This is the second block for the desktop media . -->

<template>
  
    <div class="mob_padding0 mx-auto row text-center align-items-center justify-content-center heightTitle">
      <h2 class="col-12 pinktitle"> 
        Mon expérience
      </h2>
    </div>
    <div class="mx-auto row align-items-center heightElt">
      <div class="col-12 text-center">
        <p class="my-auto rectfluo rectmoyen scrolly"> 
          
          <!-- Ici est inséré le texte -->
          <TextPage4/>


        </p>
      </div>
    </div>


</template>

<script>


import TextPage4 from '@/components/TextPage4.vue'

  export default {

    name: "Page4-c",
    components: {
      TextPage4,
    },
    data() {return {}},
    computed: {},
    methods: {},
    mounted() {}
  }

</script>


<style lang="scss">
  

  
</style>