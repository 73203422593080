<!-- This is the home page with presentation of the application. -->

<template>
  <BgHomeComponent/>
  
  
  <main>
    <HeaderComponent/>
    <div v-if="maxWidth === false" id="mainpage" class="container">
      <div class="mx-auto row page--elt" id="page1">
        <Page1Component/>
      </div>
      <div class="mx-auto row page--elt" id="page2">
        <Page2Component/>
      </div>
      <div class="mx-auto row page--elt" id="page3">
        <Page3Component/>
      </div>
      <div class="mx-auto row page--elt" id="page4">
        <Page4Component/>
      </div>
      <div class="mx-auto row page--elt" id="page5">
        <Page5Component/>

      </div>
      
    </div>

    <div v-if="maxWidth === true" id="mainpagemobile" class="container">
      <div v-show="this.$store.state.visible_p1" class="mx-auto row mobile--elt" id="page1">
        <Page1Component/>
      </div>
      <div v-show="this.$store.state.visible_p2" class="mx-auto row mobile--elt notvisible" id="page2">
        <Page2Component/>
      </div>
      <div v-show="this.$store.state.visible_p3" class="mx-auto row mobile--elt notvisible" id="page3">
        <Page3Component/>
      </div>
      <div v-show="this.$store.state.visible_p4" class="mx-auto row mobile--elt notvisible" id="page4">
        <Page4Component/>
      </div>
      <div v-show="this.$store.state.visible_p5" class="mx-auto row mobile--elt notvisible" id="page5">
        <Page5Component/>
      </div>
     
      <div id="arrow" class="mx-auto row text-center">

          <div class="col-4 btnclick" v-show="this.$store.state.flechedh" @click="fonctionduhaut" id="higharrow">
            <svg xmlns="http://www.w3.org/2000/svg" width="58" height="57" viewBox="0 0 58 57">
              <g data-name="Groupe 4" transform="translate(-182 -741)">
                <ellipse data-name="Ellipse 2" cx="29" cy="28.5" rx="29" ry="28.5" transform="translate(182 741)" fill="#00c853" opacity="0.63"/>
                <path d="M23.75,42.044,8,25.022l2.067-2.234L22.273,35.98V8h2.953V35.98L37.433,22.788,39.5,25.022Z" transform="translate(186.75 743.978)" fill="#fff"/>
              </g>
            </svg>
          </div>
          <div class="col-4 btnclick" v-show="this.$store.state.flechedb" v-on:click="fonctiondubas" id="lowarrow">
            <svg xmlns="http://www.w3.org/2000/svg" width="58" height="57" viewBox="0 0 58 57">
              <g data-name="Groupe 4" transform="translate(-182 -741)">
                <ellipse data-name="Ellipse 2" cx="29" cy="28.5" rx="29" ry="28.5" transform="translate(182 741)" fill="#00c853" opacity="0.63"/>
                <path d="M23.75,42.044,8,25.022l2.067-2.234L22.273,35.98V8h2.953V35.98L37.433,22.788,39.5,25.022Z" transform="translate(186.75 743.978)" fill="#fff"/>
              </g>
            </svg>
          </div>
      
      </div>
    </div>
    
  </main>

</template>

<script>
  import { mapMutations } from "vuex";
  import BgHomeComponent from '@/components/BgHomeComponent.vue'
  import HeaderComponent from '@/components/HeaderComponent.vue'
  import Page1Component from '@/components/Page1Component.vue'
  import Page2Component from '@/components/Page2Component.vue'
  import Page3Component from '@/components/Page3Component.vue'
  import Page4Component from '@/components/Page4Component.vue'
  import Page5Component from '@/components/Page5Component.vue'

  export default {
    name: 'Home-vue',
    components: {
      BgHomeComponent,
      HeaderComponent,
      Page1Component,
      Page2Component,
      Page3Component,
      Page4Component,
      Page5Component,

    },
    data() {
      return {}
    },
    computed: {
      // calculate the maxWidth of the screen
      maxWidth() {
        if (window.matchMedia("(max-width: 768px)").matches) {
          return true
        } else { return false } 
      },
    },
    methods: {

      ...mapMutations([ // to be possible to modify store in vuex
        "setPage1",
        "setPage2",
        "setPage3",
        "setPage4",
        "setPage5",
        "setFlecheH",
        "setFlecheB"
        ]), 

      movetopage(elementtomove) {
        elementtomove.animate([
          // étapes/keyframes
          { opacity: '0' },
          { opacity: '1' }
        ], {
          // temporisation
          delay: 300,
          duration: 2500,
          fill: "backwards",
        });
      },
      fonctiondubas() {
        if (this.$store.state.visible_p1 == true ) {
          this.setPage1(false);
          this.setPage2(true);
          this.setPage3(false);
          this.setPage4(false);
          this.setPage5(false);
          this.setFlecheH(true);
          this.setFlecheB(true);
          this.movetopage(document.getElementById('page2'));
        } else if (this.$store.state.visible_p2 == true ) {
          this.setPage1(false);
          this.setPage2(false);
          this.setPage3(true);
          this.setPage4(false);
          this.setPage5(false);
          this.setFlecheH(true);
          this.setFlecheB(true);
          this.movetopage(document.getElementById('page3'));
        } else if (this.$store.state.visible_p3 == true ) {
          this.setPage1(false);
          this.setPage2(false);
          this.setPage3(false);
          this.setPage4(true);
          this.setPage5(false);
          this.setFlecheH(true);
          this.setFlecheB(true);
          this.movetopage(document.getElementById('page4'));
        } else if (this.$store.state.visible_p4 == true ) {
          this.setPage1(false);
          this.setPage2(false);
          this.setPage3(false);
          this.setPage4(false);
          this.setPage5(true);
          this.setFlecheH(true);
          this.setFlecheB(false);
          this.movetopage(document.getElementById('page5'));      
        }  
      },
      fonctionduhaut() {
        if (this.$store.state.visible_p2 == true ) {
          this.setPage1(true);
          this.setPage2(false);
          this.setPage3(false);
          this.setPage4(false);
          this.setPage5(false);
          this.setFlecheH(false);
          this.setFlecheB(true);
          this.movetopage(document.getElementById('page1'));    
        } else if (this.$store.state.visible_p3 == true ) {
          this.setPage1(false);
          this.setPage2(true);
          this.setPage3(false);
          this.setPage4(false);
          this.setPage5(false);
          this.setFlecheH(true);
          this.setFlecheB(true);
          this.movetopage(document.getElementById('page2'));
        } else if (this.$store.state.visible_p4 == true ) {
          this.setPage1(false);
          this.setPage2(false);
          this.setPage3(true);
          this.setPage4(false);
          this.setPage5(false);
          this.setFlecheH(true);
          this.setFlecheB(true);
          this.movetopage(document.getElementById('page3'));
        } else if (this.$store.state.visible_p5 == true ) {
          this.setPage1(false);
          this.setPage2(false);
          this.setPage3(false);
          this.setPage4(true);
          this.setPage5(false);
          this.setFlecheH(true);
          this.setFlecheB(true);
          this.movetopage(document.getElementById('page4'));
        }  
      },
      
    },
    mounted() {
      // const screenH = document.body.offsetHeight; // les 2 types d'écran => erreur sur mobile car prend en compte la barre des tâche
      // const screenH = document.body.clientHeight; // les 2 types d'écran => ne prend pas en compte la barre des tâches
      const screenH = window.innerHeight; // les 2 types d'écran => ne prend pas en compte la barre des tâches
      const page1 = document.getElementById('page1') // les 2 types d'écran
      const img1 = document.getElementById('img') // les 2 types d'écran
      const img2 = document.getElementById('img2') // les 2 types d'écran
      const canv = document.getElementById('canv') // les 2 types d'écran
      const pinktitles = document.getElementsByClassName("heightTitle"); // les 2 types d'écran
      const eltsH = document.getElementsByClassName("heightElt"); // les 2 types d'écran
      const scrolls = document.getElementsByClassName("scrolly"); // les 2 types d'écran
      

      const pageElts = document.getElementsByClassName("page--elt"); // desktop
      const dimpage = document.getElementById('mainpage'); // desktop

      const mobileElts = document.getElementsByClassName("mobile--elt"); // mobile
      const dimpageM = document.getElementById('mainpagemobile'); // mobile
      


      var titleH = 0;

      var elt2 = 0;
      // if (window.matchMedia("(max-height: 700px)").matches)  {
      //   titleH = titleH /2;
      //   elt2 = titleH * 10;
      // } else {
      //   elt2 = titleH * 3;
      // }

      // Desktop
      if (pinktitles !== null && eltsH !== null && pageElts !== null && dimpage !== null)  {
      
        titleH = (screenH - 80*2) /4;
        elt2 = titleH * 3;

        for(var i = 0; i < pinktitles.length; i++) {
          pinktitles[i].style.height = titleH + "px";
        }
        for(var j = 0; j < eltsH.length; j++) {
          eltsH[j].style.height = elt2 + "px";
        }
        for(var k = 0; k < pageElts.length; k++) {
          pageElts[k].style.marginBottom = "80px";
        }
        for(var l = 0; l < scrolls.length; l++) {
          scrolls[l].style.maxHeight = elt2 + "px";
        }
        dimpage.style.top = dimpage.offsetHeight / 2 - document.body.offsetHeight /2 + "px"; 
        img1.style.height = elt2 + "px";
        img1.style.width = "auto";
      }

      // Mobile
      if (pinktitles !== null && eltsH !== null && mobileElts !== null && dimpageM !== null)  {
        titleH = (screenH - 80*2) /4;
        elt2 = titleH * 3;

        for(var m = 0; m < pinktitles.length; m++) {
          pinktitles[m].style.height = titleH /2 + "px";
        }
        for(var n = 0; n < eltsH.length; n++) {
          eltsH[n].style.height = elt2 + "px";
        }
        for(var o = 0; o < pageElts.length; o++) {
          mobileElts[o].style.height = titleH + elt2 +"px";
        }
        for(var p = 0; p < scrolls.length; p++) {
          scrolls[p].style.maxHeight = elt2 + "px";
        }
        dimpageM.style.top = dimpageM.offsetHeight / 2 - document.body.offsetHeight /2 + "px"; 
        img2.style.height = elt2 + "px";
        img2.style.width = "auto";
        img1.style.height = elt2 / 2.5 + "px";
        img1.style.width = "auto";

      }

      page1.scrollIntoView({block: "start"})

      const strangerthings = document.getElementById("principalTitle");
      const firstrect = document.getElementById("firstRect");
      const timeanim = 3000;
        
      strangerthings.animate([
        // étapes/keyframes
        { transform: 'scale(5)', opacity: '0' },
        { transform: 'scale(1)', opacity: '1' }
      ], {
        // temporisation
        delay: 500,
        duration: timeanim,
        fill: "backwards",
      });

      img1.animate([
        // étapes/keyframes
        { opacity: '0' },
        { opacity: '1'  }
      ], {
        // temporisation
        delay: timeanim,
        duration: 1000,
        fill: "backwards",
      });

      firstrect.animate([
        // étapes/keyframes
        { opacity: '0' },
        { opacity: '1'  }
      ], {
        // temporisation
        delay: timeanim + 500,
        duration: 1000,
        fill: "backwards",
      });

      canv.style.height = screenH + "px";
    


     
    }

  }


</script>

<style lang="scss">
  
 
  
  main {
    width: 100vw;
    z-index : 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position:absolute;
    left:0px;
    top:0px; 
    // position:relative;
    // top: -100vh;
    background-color: transparent;
    overflow-x: hidden;
    height : 100%;
  } 
  

  #mainpage {
    // --heightcontainer: 4960px;
    // --topposition: calc(var(--heightcontainer) * 0.5);
    position: relative;
    // top:calc(2530px);
    // top: var(--topposition);
    top: 0px;
    // @media (min-height: 230px) {
    //   top:calc(2530px);
    // }
    // @media (min-height: 400px) {
    //   top: calc(var(--topposition) - 100px);
    // }
    // @media (min-height: 500px) {
    //   top:calc(var(--topposition) - 200px);
    // }
    // @media (min-height: 800px) {
    //   top:calc(var(--topposition) - 300px);
    // }
  }

  #mainpagemobile {

    // overflow-y : scroll;
  }

  #arrow {

    display: flex;
    align-items: center;
    justify-content: center;
    position:absolute;
    left:0px;
    bottom:10px; 
    width:100%;

  }

  #higharrow {
    transform: rotateX(0.5turn);
  }

</style>