<!-- This is the second block for the desktop media . -->

<template>
  
    <div class="mob_padding0 mx-auto row text-center align-items-center justify-content-center heightTitle">
      <h2 class="col-12 pinktitle"> 
        Qui suis-je ?
      </h2>
    </div>
    <div class="mx-auto row align-items-center heightElt">
      <div class="col-12 text-center">
        <p class="my-auto rectfluo rectlarge scrolly"> 
          
          <!-- Ici est inséré le texte -->
          <TextPage2/>

        </p>
      </div>
    </div>


</template>

<script>

import TextPage2 from '@/components/TextPage2.vue'

  export default {

    name: "Page2-c",
    components: {
      TextPage2,
    },
    data() {return {}},
    computed: {},
    methods: {},
    mounted() {}
  }

</script>


<style lang="scss">
  
  
  
</style>