<!-- This is the second block for the desktop media . -->

<template>
  
    <div class="mob_padding0 mx-auto row text-center align-items-center justify-content-center heightTitle">
      <h2 class="col-12 pinktitle"> 
        Mes compétences
      </h2>
    </div>

    <!-- If max-width of the screen is < 768 px this part is active -->
    <div  v-if="maxWidth === true" class="mx-auto row align-items-center heightElt">
      <div class="col-12 lineheight">
        <div class="my-auto rectfluo rectetroit scrolly"> 
          <div class="marginbot mx-auto row align-items-center justify-content-around ">
            <div class="heightcol col-2 text-center">
              <h3 class="textvertical">
                HARD&nbsp;SKILLS
              </h3>
              
            </div>
            <div class="pointilles col-10">

              <!-- Ici est inséré le texte -->
              <TextPage3HS/>

            </div>
          </div>

          <div class=" mx-auto row align-items-center justify-content-around">
            <div class="heightcol col-2 text-center">
              <h3 class="textvertical">
                SOFT&nbsp;SKILLS
              </h3>
              
            </div>
            <div class="pointilles col-10">

              <!-- Ici est inséré le texte -->
              <TextPage3SS/>

            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- If max-width of the screen is < 768 px this part is active -->
    <div  v-if="maxWidth === false" class="mx-auto row align-items-center heightElt">
      <div class="col-6 lineheight">
        <div class="my-auto rectfluo rectetroit scrolly"> 
          <div class="mx-auto row align-items-center justify-content-around">
            <div class="heightcol col-2 text-center">
              <h3 class="textvertical">
                HARD&nbsp;SKILLS
              </h3>
              
            </div>
            <div class="pointilles col-10">

              <!-- Ici est inséré le texte -->
              <TextPage3HS/>

            </div>
          </div>
        </div>
      </div>
      <div class="col-6 ">
        <div class="my-auto rectfluo rectetroit scrolly"> 
          <div class="mx-auto row align-items-center justify-content-around">
            <div class="heightcol col-2 text-center">
              <h3 class="textvertical">
                SOFT&nbsp;SKILLS
              </h3>
              
            </div>
            <div class="pointilles col-10">

              <!-- Ici est inséré le texte -->
              <TextPage3SS/>

            </div>
          </div>
        </div>
      </div>
    </div>


</template>

<script>

import TextPage3HS from '@/components/TextPage3HS.vue'
import TextPage3SS from '@/components/TextPage3SS.vue'

  export default {

    name: "Page3-c",
    components: {
      TextPage3HS,
      TextPage3SS,
    },
    data() {return {}},
    computed: {
      
      // Calculate the max-width of the screen
      maxWidth() {
        if (window.matchMedia("(max-width: 768px)").matches) {
          return true
        }
        else { return false }
      }
    },
    methods: {},
    mounted() {}
  }

</script>


<style lang="scss">
.heightcol{

  // height:100px;
  transform: translatey(20px);
  // text-overflow: clip;
  // padding : -100px;
  
}
.lineheight{
  line-height : 1.2rem;
}

.textvertical {
    // transform-origin: 10px 20px;
    transform: rotate(-90deg); /* Équivalent à rotateZ(-90deg) */
    font-size : 20px;

    // width:300px;
    
}

.marginbot{
  margin-bottom:30px;
}


  
  
</style>