
<template>

  <span class="underline"> LANGAGES : <br></span>
  <p>
    Javascript / Python / VBA / SQL <br>
  </p>
  <span class="underline"> FRONTEND : <br></span>
  <ul>
    <li>HTML / CSS (Sass) / Bootstrap</li>
    <li>Vue.js</li>
    <li>Ui / Ux Design</li>
    <li>Wordpress (Elementor / Divi) + Woocommerce</li>
    <li>Optimisation SEO</li>
  </ul>
  <span class="underline"> BACKEND : <br></span>
  <ul>
    <li>Node.js / Express.js</li>
    <li>Flask / Django</li>
    <li>MySQL / MongoDB</li>
    <li>Rest API</li>
  </ul>
  <span class="underline"> DIVERS : <br></span>
  <p>
    Docker / Git / VScode <br>
  </p>
  <span class="underline"> LOGICIELS : <br></span>
  <ul>
    <li>Suite Adobe (Ai / Xd / Ps / Pp)</li>
    <li>Microsoft 365 (ppt / xls / doc)</li>
  </ul>
  
</template>

<script>

  export default {

    name: "TexteP3HS",
    data() {return {}},
    computed: {},
    methods: {},
    mounted() {}
  }

</script>


<style lang="scss">

  
</style>