
<template>
  <div v-for="item in items" v-bind:key="item.name" class="row">
    <div class="col-5 text-center">
      <svg xmlns="http://www.w3.org/2000/svg" width="73.417" height="13.965" viewBox="0 0 73.417 13.965">
        <g transform="translate(-256 -432.335)">
          
          <path v-if="(item.starqty >= 1)" class="greenstar" data-name="Icon metro-star-full" d="M17.254,8.017,12.181,7.28l-2.269-4.6L7.644,7.28l-5.073.737L6.242,11.6l-.867,5.052,4.537-2.385,4.537,2.385L13.583,11.6l3.671-3.578Z" transform="translate(253.429 429.653)" fill="#fff"/>
          <path v-if="(item.starqty >= 2)" class="greenstar" data-name="Icon metro-star-full" d="M17.254,8.017,12.181,7.28l-2.269-4.6L7.644,7.28l-5.073.737L6.242,11.6l-.867,5.052,4.537-2.385,4.537,2.385L13.583,11.6l3.671-3.578Z" transform="translate(268.113 429.653)" fill="#fff"/>
          <path v-if="(item.starqty >= 3)" class="greenstar" data-name="Icon metro-star-full" d="M17.254,8.017,12.181,7.28l-2.269-4.6L7.644,7.28l-5.073.737L6.242,11.6l-.867,5.052,4.537-2.385,4.537,2.385L13.583,11.6l3.671-3.578Z" transform="translate(282.796 429.653)" fill="#fff"/>
          <path v-if="(item.starqty >= 4)" class="greenstar" data-name="Icon metro-star-full" d="M17.254,8.017,12.181,7.28l-2.269-4.6L7.644,7.28l-5.073.737L6.242,11.6l-.867,5.052,4.537-2.385,4.537,2.385L13.583,11.6l3.671-3.578Z" transform="translate(297.479 429.653)" fill="#fff"/>
          <path v-if="(item.starqty >= 5)" class="greenstar" data-name="Icon metro-star-full" d="M17.254,8.017,12.181,7.28l-2.269-4.6L7.644,7.28l-5.073.737L6.242,11.6l-.867,5.052,4.537-2.385,4.537,2.385L13.583,11.6l3.671-3.578Z" transform="translate(312.163 429.653)" fill="#fff"/>
        
          <path v-if="(item.starqty < 1)" class="whitestar" data-name="Icon metro-star-full" d="M17.254,8.017,12.181,7.28l-2.269-4.6L7.644,7.28l-5.073.737L6.242,11.6l-.867,5.052,4.537-2.385,4.537,2.385L13.583,11.6l3.671-3.578Z" transform="translate(253.429 429.653)" fill="#fff"/>
          <path v-if="(item.starqty < 2)" class="whitestar" data-name="Icon metro-star-full" d="M17.254,8.017,12.181,7.28l-2.269-4.6L7.644,7.28l-5.073.737L6.242,11.6l-.867,5.052,4.537-2.385,4.537,2.385L13.583,11.6l3.671-3.578Z" transform="translate(268.113 429.653)" fill="#fff"/>
          <path v-if="(item.starqty < 3)" class="whitestar" data-name="Icon metro-star-full" d="M17.254,8.017,12.181,7.28l-2.269-4.6L7.644,7.28l-5.073.737L6.242,11.6l-.867,5.052,4.537-2.385,4.537,2.385L13.583,11.6l3.671-3.578Z" transform="translate(282.796 429.653)" fill="#fff"/>
          <path v-if="(item.starqty < 4)" class="whitestar" data-name="Icon metro-star-full" d="M17.254,8.017,12.181,7.28l-2.269-4.6L7.644,7.28l-5.073.737L6.242,11.6l-.867,5.052,4.537-2.385,4.537,2.385L13.583,11.6l3.671-3.578Z" transform="translate(297.479 429.653)" fill="#fff"/>
          <path v-if="(item.starqty < 5)" class="whitestar" data-name="Icon metro-star-full" d="M17.254,8.017,12.181,7.28l-2.269-4.6L7.644,7.28l-5.073.737L6.242,11.6l-.867,5.052,4.537-2.385,4.537,2.385L13.583,11.6l3.671-3.578Z" transform="translate(312.163 429.653)" fill="#fff"/>
        
        </g>
      </svg>
    </div>
    <p class="col-7">{{item.name}} </p>
  </div>

</template>

<script>

  export default {

    name: "TexteP3HS",
    data() {
      return {
        items: [
          {name:"Organisation", starqty: 4 },
          {name:"Ponctualité", starqty: 5 },
          {name:"Honnêteté", starqty: 5 },
          {name:"Communication", starqty: 4 },
          {name:"Créativité", starqty: 4 },
          {name:"Travail d'équipe", starqty: 4 },
          {name:"Agilité", starqty: 5 },
          {name:"Curiosité", starqty: 5 },
          {name:"Empathie", starqty: 4 },
        ]
      }
    },
    computed: {},
    methods: {},
    mounted() {}
  }

</script>


<style lang="scss">

  
</style>