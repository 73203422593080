<!-- This is the header component for all pages except Signin and Signup vue. -->

<template>
  <div id="background">
      <canvas id="canv"></canvas>
      <div id="bg-transparent"></div>
  </div>
</template>

<script>

  
  export default {

    name: "BG-home-c",
    data() {return {}},
    computed: {},
    methods: {},
    mounted() {

      const canvas = document.getElementById('canv');
      const ctx = canvas.getContext('2d');
      // const btnclick = document.getElementById('lowarrow');
      // const btnclick2 = document.getElementById('higharrow');

      // set the width and height of the canvas
      const w = canvas.width = document.body.offsetWidth;
      // const h = canvas.height = document.body.offsetHeight;
      const h = canvas.height = window.innerHeight;
      const cols = Math.floor(w / 20) + 1;
      const ypos = Array(cols).fill(0);

      // draw a black rectangle of width and height same as that of the canvas
      ctx.fillStyle = '#000';
      ctx.fillRect(0, 0, w , h);

      var tab = Array(cols).fill(0);

      function matrix () {

        // Draw a semitransparent black rectangle on top of previous drawing
        ctx.fillStyle = '#0001';
        ctx.fillRect(0, 0, w, h);
          
        // Set color to green and font to 15pt monospace in the drawing context
        ctx.fillStyle = '#0f0';
        ctx.font = '15pt monospace';
          


        // for each column put a random character at the end
        ypos.forEach((y, ind) => {
          if (tab[ind]== 0) {
            // generate a random character
            const text = String.fromCharCode(Math.random() * 128);
            // x coordinate of the column, y coordinate is already given
            const x = ind * 20;
            // render the character at (x, y)
            ctx.fillText(text, x, y);
            // randomly reset the end of the column if it's at least 100px high
            if (y > 100 + Math.random() * 10000){
              ypos[ind] = 0;
              tab[ind] = 1
            // otherwise just move the y coordinate for the column 20px down,
            } else ypos[ind] = y + 20;
          } else {
            // generate a random character
            const text = String.fromCharCode(Math.random() * 128);
            // x coordinate of the column, y coordinate is already given
            const x = ind * 20;
            // render the character at (x, y)
            ctx.fillText(text, x, y);
            // randomly reset the end of the column if it's at least 100px high
            if (y > 100 + Math.random() * 10000) ypos[ind] = 0;
            // otherwise just move the y coordinate for the column 20px down,
            else ypos[ind] = y + 20;   
          }
        })

      }

      function transimatrix(introtime){

        var id;

        id = setInterval(matrix, 40);
        setTimeout(() => { 
          clearInterval(id); 
          id = setInterval(matrix, 50);
          
          setTimeout(() => { 
            clearInterval(id); 
            id = setInterval(matrix, 60);
            
            setTimeout(() => { 
              clearInterval(id); 
              id = setInterval(matrix, 70);
              
              setTimeout(() => { 
                clearInterval(id); 
                id = setInterval(matrix, 80);
                
                setTimeout(() => { 
                  clearInterval(id); 
                  id = setInterval(matrix, 90);

                  setTimeout(() => { 
                    clearInterval(id); 
                    id = setInterval(matrix, 100);
                    
                    setTimeout(() => { 
                      clearInterval(id); 
                      id = setInterval(matrix, 200);
                    
                      setTimeout(() => { 
                        clearInterval(id); 
                        id = setInterval(matrix, 400);
                      
                        setTimeout(() => { 
                          clearInterval(id); 
                        
                        }, 30);
                      }, 60);
                    }, 80);
                  }, 100);
                }, 200);
              }, 300);
            }, 300);
          }, 300);
        }, introtime);

      }
      
      document.onwheel = matrix;
      document.ondrag = matrix;
      transimatrix(2000);
        
      const btnclicks = document.getElementsByClassName("btnclick");
      if (btnclicks !== null)  {
      
        for(var i = 0; i < btnclicks.length; i++) {
          btnclicks[i].onclick = function(){transimatrix(500)};
        }
      }


      

    },

};

</script>


<style lang="scss">

  
  #background{
    margin:0 ;
  }

  #canv{ 
    display: flex;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    margin: 0;
    background: #131313;
    color: #fff;
    font-size: 96px;
    font-family: 'Fira Mono', monospace;
    letter-spacing: -7px;
    z-index: 0;
    background-attachment: scroll;
    // background-position: center;
    // background-repeat: repeat;
    // background-size: cover;
    padding:0;

  }

  #bg-transparent{
    width: 100vw;
    height: 450px;
    z-index : 1;
    display: flex;
    position:absolute;
    left:0px;
    top:0px; 
    background: linear-gradient(to top, transparent, black);
    position:fixed;
  }
</style>