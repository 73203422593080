<!-- This is the first block for the desktop media . -->

<template>

        <div class="mob_padding0 mx-auto row text-center align-items-center justify-content-center heightTitle">

          <h1 v-on:click="scrollto2" class="col-12 pinktitle" id="principalTitle"> 
          Web Designer / Web Developer <br> FREELANCE
          </h1>

        </div>
        <div class="mob_padding0 mx-auto row align-items-center heightElt">
          <div class="col-12 col-md-6 align-self-center" id="photop1">
            <img id="img" alt="Image de profil" src="../assets/taille2.png">
          </div>
          <div class="col-12 col-md-6 text-center">
            <div class="mx-auto row align-items-center justify-content-between">
              
              <div class="mx-auto row text-center justify-content-center">
                <p class="my-auto rectfluo rectlarge scrolly" id="firstRect"> 

                <!-- Ici est inséré le texte -->
                  <TextPage1/>

                </p>
              </div>
              
            </div>
          </div>
        </div>

</template>

<script>

import TextPage1 from '@/components/TextPage1.vue'

  export default {

    name: "Page1-c",
    components: {
      TextPage1,
    },
    data() {return {}},
    computed: {},
    methods: {
      scrollto2() {
        
        window.scrollTo(0, 500);
      },
    },
    mounted() {}
  }

</script>


<style lang="scss">
  
  
  
  
</style>