<!-- This is the header component -->

<template>
  <header id="headerID">
    <div class = "row align-items-center justify-content-between">
      <div v-if="maxWidth === true" class="col-3 col-md-4 text-center">

        <svg @click="gotopage1" id="logomobile" class="hovericon btnclick" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="57" height="61" viewBox="0 0 57 61">
          <defs>
            <clipPath id="clip-path"> 
              <ellipse id="Ellipse_1" data-name="Ellipse 1" cx="28.5" cy="30.5" rx="28.5" ry="30.5" transform="translate(151 213.453)" fill="#fff"/>
            </clipPath>
          </defs>
          <g id="Logo_GA" data-name="Logo GA" transform="translate(-151 -213.453)" clip-path="url(#clip-path)">
            <g id="Logo_GA_2_" data-name="Logo GA (2)" transform="translate(151 225.157)">
              <path id="Tracé_1" data-name="Tracé 1" d="M99.635,38.128H95.076c-1.834-4.688-9.508-24.294-11.343-28.984-.377.965-.789,2.018-1.211,3.1H78.3Q80.353,7.068,82.4,1.9h2.893Z" transform="translate(-43.283 -0.976)" fill="#0e8b1b"/>
              <rect id="Rectangle_2" data-name="Rectangle 2" width="26.539" height="4.579" transform="translate(14.65 17.204)" fill="#0ab81c"/>
              <path id="Tracé_2" data-name="Tracé 2" d="M0,18.9C0,7.187,8.118,0,17.738,0A19.237,19.237,0,0,1,29.3,3.8V8.817A15.95,15.95,0,0,0,17.738,4.047c-7.672,0-13.122,5.8-13.122,14.854s5.448,14.854,13.122,14.854A15.94,15.94,0,0,0,29.3,28.984V34a19.237,19.237,0,0,1-11.565,3.8C8.118,37.8,0,30.614,0,18.9Z" fill="#2be362"/>
            </g>
          </g>
        </svg>

      </div>

      <div v-if="maxWidth === false" class="col-3 col-md-4 text-center">

        <svg @click="scrollto('page1')" class="hovericon btnclick" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="57" height="61" viewBox="0 0 57 61">
          <defs>
            <clipPath id="clip-path"> 
              <ellipse id="Ellipse_1" data-name="Ellipse 1" cx="28.5" cy="30.5" rx="28.5" ry="30.5" transform="translate(151 213.453)" fill="#fff"/>
            </clipPath>
          </defs>
          <g id="Logo_GA" data-name="Logo GA" transform="translate(-151 -213.453)" clip-path="url(#clip-path)">
            <g id="Logo_GA_2_" data-name="Logo GA (2)" transform="translate(151 225.157)">
              <path id="Tracé_1" data-name="Tracé 1" d="M99.635,38.128H95.076c-1.834-4.688-9.508-24.294-11.343-28.984-.377.965-.789,2.018-1.211,3.1H78.3Q80.353,7.068,82.4,1.9h2.893Z" transform="translate(-43.283 -0.976)" fill="#0e8b1b"/>
              <rect id="Rectangle_2" data-name="Rectangle 2" width="26.539" height="4.579" transform="translate(14.65 17.204)" fill="#0ab81c"/>
              <path id="Tracé_2" data-name="Tracé 2" d="M0,18.9C0,7.187,8.118,0,17.738,0A19.237,19.237,0,0,1,29.3,3.8V8.817A15.95,15.95,0,0,0,17.738,4.047c-7.672,0-13.122,5.8-13.122,14.854s5.448,14.854,13.122,14.854A15.94,15.94,0,0,0,29.3,28.984V34a19.237,19.237,0,0,1-11.565,3.8C8.118,37.8,0,30.614,0,18.9Z" fill="#2be362"/>
            </g>
          </g>
        </svg>

      </div>
      
      <div id="titre" @click="gotopage('/')" class="col-6 col-md-4 text-center" >

          <span >Grégoire AIMIER</span> 

      </div>
      
      <!-- If max-width of the screen is < 768 px this part is active -->
      <div v-if="maxWidth === true" class="col-3 col-md-4 text-center">
				
        <svg id="iconMenu" @click="menu" xmlns="http://www.w3.org/2000/svg" width="36" height="24" viewBox="0 0 36 24">
          <path id="icone_menu" data-name="icone menu" d="M6,36V33H42v3ZM6,25.5v-3H42v3ZM6,15V12H42v3Z" transform="translate(-6 -12)" fill="#00c853"/>
        </svg>
       <nav v-show="visible_nav" >
        <div id="nav">
            <div id="menu" class="mx-auto row text-center align-items-center">
              <p @click="menugoto1" class="btnclick">Accueil</p>
              <p @click="menugoto2" class="btnclick">Qui suis-je ?</p>
              <p @click="menugoto3" class="btnclick">Compétences</p>
              <p @click="menugoto4" class="btnclick">Expérience</p>
              <p @click="menugoto5" class="btnclick">Contact</p>
            </div>
          </div>
       </nav>

      </div>
              
      <!-- If max-width of the screen is > 768 px this part is active -->
      <div v-if="maxWidth === false" class="col-3 col-md-4 text-center" id="headericons">
        <svg @click="scrollto('page2')" class="hovericon btnclick" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 67.097 67.1">
          <path class="greenstar" id="Icon_ionic-ios-person" data-name="Icon ionic-ios-person" d="M71.564,69.905c-1.258-5.557-8.44-8.265-10.921-9.139-2.726-.961-6.605-1.188-9.1-1.747-1.433-.315-3.512-1.1-4.211-1.94s-.28-8.615-.28-8.615a25.918,25.918,0,0,0,1.992-3.792,48.91,48.91,0,0,0,1.468-6.623s1.433,0,1.94-2.516c.542-2.743,1.4-3.809,1.293-5.854-.1-2.01-1.206-1.957-1.206-1.957S53.6,24.8,53.723,18.759C53.881,11.577,48.254,4.5,38.067,4.5c-10.327,0-15.832,7.077-15.674,14.259a31.956,31.956,0,0,0,1.171,8.964s-1.1-.052-1.206,1.957c-.1,2.044.751,3.11,1.293,5.854.489,2.516,1.94,2.516,1.94,2.516a48.91,48.91,0,0,0,1.468,6.623,25.919,25.919,0,0,0,1.992,3.792s.419,7.776-.28,8.615-2.778,1.625-4.211,1.94c-2.5.559-6.378.786-9.1,1.747-2.481.874-9.663,3.582-10.921,9.139A1.4,1.4,0,0,0,5.914,71.6H70.2A1.394,1.394,0,0,0,71.564,69.905Z" transform="translate(-4.501 -4.5)" fill="#fff"/>
        </svg>
        <svg @click="scrollto('page3')" class="hovericon btnclick" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 60.323 55.855">
          <path class="greenstar" id="Icon_metro-suitcase" data-name="Icon metro-suitcase" d="M60.353,61.639H8.967A4.469,4.469,0,0,1,4.5,57.17V30.36s11.665,6,22.342,8.454v2.717a2.232,2.232,0,0,0,2.234,2.234H40.246a2.234,2.234,0,0,0,2.234-2.234V38.814c10.675-2.455,22.342-8.454,22.342-8.454V57.17A4.47,4.47,0,0,1,60.353,61.639ZM38.012,34.829a2.234,2.234,0,0,1,2.234,2.234V39.3a2.234,2.234,0,0,1-2.234,2.234h-6.7A2.232,2.232,0,0,1,29.075,39.3V37.063a2.232,2.232,0,0,1,2.234-2.234Zm4.468,0a2.234,2.234,0,0,0-2.234-2.234H29.075a2.232,2.232,0,0,0-2.234,2.234v2C16.163,34.3,4.5,28.126,4.5,28.126V19.189a4.469,4.469,0,0,1,4.468-4.468H22.372V10.252a4.47,4.47,0,0,1,4.468-4.468H42.48a4.47,4.47,0,0,1,4.468,4.468v4.468H60.353a4.47,4.47,0,0,1,4.468,4.468v8.937S53.155,34.3,42.48,36.83v-2Zm0-22.342a2.234,2.234,0,0,0-2.234-2.234H29.075a2.232,2.232,0,0,0-2.234,2.234v2.234H42.48Z" transform="translate(-4.499 -5.784)" fill="#fff"/>
        </svg>
        <svg @click="scrollto('page4')" class="hovericon btnclick" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 60.1 60.1">
          <path class="greenstar" id="Icon_material-stars" data-name="Icon material-stars" d="M33.02,3A30.05,30.05,0,1,0,63.1,33.05,30.036,30.036,0,0,0,33.02,3ZM45.761,51.08,33.05,43.418,20.339,51.08,23.7,36.626,12.5,26.92l14.785-1.262,5.77-13.643,5.77,13.613L53.6,26.89,42.4,36.6,45.761,51.08Z" transform="translate(-3 -3)" fill="#fff"/>
        </svg>
        <svg @click="scrollto('page5')" class="hovericon btnclick" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 78.766 67.686">
          <path class="greenstar" id="Icon_awesome-envelope" data-name="Icon awesome-envelope" d="M77.274,26.851a.922.922,0,0,1,1.492.828V63.726c0,4.671-3.308,8.461-7.384,8.461h-64C3.308,72.186,0,68.4,0,63.726V27.7c0-.881.877-1.375,1.492-.828C4.938,29.935,9.507,33.831,25.2,46.892c3.246,2.715,8.723,8.426,14.184,8.39,5.492.053,11.076-5.782,14.2-8.39C69.274,33.831,73.828,29.918,77.274,26.851ZM39.383,49.624c3.569.071,8.707-5.147,11.292-7.3C71.09,25.352,72.643,23.872,77.351,19.641a4.466,4.466,0,0,0,1.415-3.331V12.961c0-4.671-3.308-8.461-7.384-8.461h-64C3.308,4.5,0,8.29,0,12.961V16.31a4.494,4.494,0,0,0,1.415,3.331c4.708,4.213,6.261,5.711,26.676,22.685C30.676,44.477,35.814,49.695,39.383,49.624Z" transform="translate(0 -4.5)" fill="#fff"/>
        </svg>
      </div>
    
    </div>
  </header>
</template>

<script>
  import { mapMutations } from "vuex";

  export default {

    name: "Header-c",
    data() {return {

      visible_nav: false,
    }},
    computed: {

      // Calculate the max-width of the screen
      maxWidth() {
        if (window.matchMedia("(max-width: 768px)").matches) {
          return true
        }
        else { return false }
      }
    },
    methods: {

      ...mapMutations([ // to be possible to modify store in vuex
        "setPage1",
        "setPage2",
        "setPage3",
        "setPage4",
        "setPage5",
        "setFlecheH",
        "setFlecheB"
      ]), 

      
      movetopage(elementtomove) {
        elementtomove.animate([
          // étapes/keyframes
          { opacity: '0' },
          { opacity: '1' }
        ], {
          // temporisation
          delay: 300,
          duration: 2500,
          fill: "backwards",
        });

      },
      
      scrollto(idelement) {
        this.visible_nav= false;
        const elementtomove = document.getElementById(idelement);
        this.movetopage(elementtomove);
        // elementtomove.animate([
        //   // étapes/keyframes
        //   { opacity: '0' },
        //   { opacity: '1' }
        // ], {
        //   // temporisation
        //   delay: 300,
        //   duration: 2500,
        //   fill: "backwards",


        // });
        //$('#content').animate({ scrollTop: elementOffset }, 200);
        elementtomove.scrollIntoView({block: "center", inline: "nearest"});
        
      },
      gotopage(linkpage){
          // this.$router.push("/");
          window.open(linkpage,"_self")
        },
      
      menu(){
        if (this.visible_nav == false) {
          this.visible_nav = true;
        }else{
          this.visible_nav = false;
        }

      },

      

      menugoto1() {
        this.setPage1(true);
        this.setPage2(false);
        this.setPage3(false);
        this.setPage4(false);
        this.setPage5(false);
        this.setFlecheH(false);
        this.setFlecheB(true);
        this.visible_nav = false;
        this.movetopage(document.getElementById('page1'));
      },
      menugoto2() {
        this.setPage1(false);
        this.setPage2(true);
        this.setPage3(false);
        this.setPage4(false);
        this.setPage5(false);
        this.setFlecheH(true);
        this.setFlecheB(true);
        this.visible_nav = false;
        this.movetopage(document.getElementById('page2'));
      },
      menugoto3() {
        this.setPage1(false);
        this.setPage2(false);
        this.setPage3(true);
        this.setPage4(false);
        this.setPage5(false);
        this.setFlecheH(true);
        this.setFlecheB(true);
        this.visible_nav = false;
        this.movetopage(document.getElementById('page3'));
      },
      menugoto4() {
        this.setPage1(false);
        this.setPage2(false);
        this.setPage3(false);
        this.setPage4(true);
        this.setPage5(false);
        this.setFlecheH(true);
        this.setFlecheB(true);
        this.visible_nav = false;
        this.movetopage(document.getElementById('page4'));
      },
      menugoto5() {
        this.setPage1(false);
        this.setPage2(false);
        this.setPage3(false);
        this.setPage4(false);
        this.setPage5(true);
        this.setFlecheH(true);
        this.setFlecheB(false);
        this.visible_nav = false;
        this.movetopage(document.getElementById('page5'));
      },

      gotopage1(){
        this.menugoto1();
      },
      
    },

    mounted() {}
  }

</script>


<style lang="scss">


    @import '../main.scss';

  header {
  margin: 0;
  padding:0;
  width: 100vh;
  height: 80px;

  display: flex;
  z-index : 51;
  align-items: center;
  justify-content: center;

  justify-content: space-around;
  right:0px;
  left:0px;
  top:0px; 
  background: linear-gradient(to top, transparent, black);
  position:fixed;
} 

header div {

  width: 100vw;
  align-items: center ;
}




#headerID {
  margin:0;
  width:100%;
}

#headericons svg {
  margin:10px;
}



#iconMenu{

  z-index: 100;
  
}
  
</style>